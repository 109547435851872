.arrow {
    cursor: pointer;
}

.arrow path {
    stroke: var(--grey-01);
    transition: 0.3s ease-in-out;
}

.arrow:hover path {
    stroke: var(--orange);
}

.arrow_selected {
    animation: rotate 0.2s ease-in-out forwards;
}

@keyframes rotate {
    0% { transform: rotateX(0deg); }
    100% { transform: rotateX(180deg); }
}