/* main styles */
.btn {
    font-family: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    height: 60px;
    padding: 20px 30px;
    background-color: var(--orange);
    border: 1px solid var(--orange);
    border-radius: 30px;
    color: #fff;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
}

.btn:hover {
    box-shadow: 0px 4px 30px rgba(237, 105, 42, 0.4);
}

.btn path {
    fill: #fff;
}

.btn span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

/* light styles */

.btn_light,
.btn_light path {
    border-color: #B2B2C1;
    background-color: #fff;
    color: var(--grey-01);
    fill: var(--grey-01);
}

.btn_light:hover {
    border-color: var(--orange);
}

/* login styles */

.btn_login {
    height: 82px;
    border-radius: 60px;
}

.btn_login span {
    font-family: monospace;
}

.btn_login,
.btn_login path {
    border-color: #D400FF;
    background-color: #D400FF;
    color: #fff;
    fill: #fff;
}

.btn_light:hover {
    border-color: var(--orange);
}

/* small styles */

.btn_small {
    height: 50px;
}

/* x-small styles */

.btn_x_small {
    height: 20px;
}

/* disabled styles */

.btn_disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn_disabled:hover {
    box-shadow: none;
}

.btn_light.btn_disabled:hover {
    border-color: #B2B2C1;
}

/* return back styles */

.btn_return {
    height: 40px;
    padding: 10px 20px;
    color: var(--grey-01);
    background-color: var(--grey-03);
    border-color: var(--grey-03);
}

.btn_return span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}