/* block styles */

.block {
    width: 100%;
    max-width: 335px;
    padding: 30px;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.block_extra_large {
    max-width: 660px;
    /* width: 600px; */
    width: 100%;
    padding: 60px;
}

.block_large {
    max-width: 454px;
    width: 454px;
    padding: 60px;
}

/* block btn */

.block_close_btn {
    position: absolute;
    top: 13px;
    right: 13px;
    background-color: transparent;
    cursor: pointer;
}

.block_close_btn path {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.block_close_btn svg:hover path {
    stroke: var(--orange);
}

/* block colored */

.block_colored {
    padding: 20px;
    background-color: var(--grey-02);
}

/* block login */

.block_login {
    background-color: #151515;
}

/* block shadowed */

.block_shadowed {
    box-shadow: 0px 4px 80px rgba(32, 36, 57, 0.1);
}