/* table styles */

.table {
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
}

/* table row */

.table_row {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
    height: 50px;

    display: grid;
    /* grid-template-columns: repeat(8, 1fr); */
    /* grid-template-columns: auto; */
    gap: 15px;
    align-items: center;

    background-color: #fff;
    border-radius: 14px;
}

.table_row_th {
    color: var(--grey-01);
    background-color: #E7E7F9;
}

/* table row item */

.table_item {
    display: flex;
    align-items: center;
    gap: 6px;

    width: 100%;
    /* max-width: 70px; */
    position: relative;
    overflow: hidden;
    /* border: 1px solid red; */
    white-space: nowrap;
    overflow-x: auto;
}

.table_item_red p::before,
.table_item_green p::before,
.table_item_dark p::before,
.table_item_yellow p::before,
.table_item_blue p::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-bottom: 1px;
    margin-right: 8px;
}

.table_item_red p::before {
    background-color: #F25858;
}

.table_item_green p::before {
    background-color: #49DE60;
}

.table_item_dark p::before {
    background-color: var(--grey-01);
}

.table_item_yellow p::before {
    background-color: #E6DA0E;
}

.table_item_blue p::before {
    background-color: #2FC7F7;
}

/* 
.table_item_large {
    max-width: 140px;
} */

/* row controls */

.row_controls {
    justify-content: flex-end;
    display: flex;
    gap: 15px;
    align-items: center;
    
    /* border: 1px solid red; */
}

.row_controls svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.row_controls * {
    transition: all 0.3s ease-in-out;
}

/* edit btn */

.row_controls .edit path {
    fill: var(--grey-01);
}

.row_controls .edit:hover path {
    fill: var(--orange);
}

/* bitrix btn */

.row_controls .bitrix path {
    stroke: #2FC7F7;
}

.row_controls .bitrix:hover path {
    stroke: var(--orange);
}

.row_controls .bitrix path:nth-child(1) {
    fill: #2FC7F7;
}

.row_controls .bitrix:hover path:nth-child(1) {
    fill: var(--orange);
}

/* copy btn */

.copy_btn {
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    line-height: 0;
}

.copy_btn svg {
    width: 16px;
    height: 16px;
}

.copy_btn svg > * {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.copy_btn svg:hover > * {
    stroke: var(--orange);
}

.sort_btn {
    width: 20px;
    height: 20px;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    cursor: pointer;
}

.sort_btn path {
    stroke: var(--grey-01);
    -webkit-transition: fill 0.3s ease-in-out;
    -o-transition: fill 0.3s ease-in-out;
    transition: stroke 0.3s ease-in-out;
}

.sort_btn:hover path {
    stroke: var(--orange);
}

.sort_btn_asc {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}

.wa_btn {
    min-width: 12.5px;
    min-height: 12.5px;
    width: 12.5px;
    height: 12.5px;
    cursor: pointer;
}

.wa_btn .wa_btn_path_green {
    -webkit-transition: fill 0.3s ease-in-out;
    -o-transition: fill 0.3s ease-in-out;
    transition: fill 0.3s ease-in-out;
    fill: var(--grey-01);
}

.wa_btn:hover .wa_btn_path_green {
    fill: var(--orange);
}