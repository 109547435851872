.select {
    position: relative;
    z-index: 1;
    /* width: 100%; */
}

.select_opened {
    z-index: 2;
}

/* select arrow */

.select_arrow {
    position: absolute;
    z-index: 2;
    right: 14px;
    top: 18px;
    pointer-events: none;
}

.select_arrow path {
    stroke: var(--grey-01);
    transition: 0.3s ease-in-out;
}

.select_small .select_arrow {
    width: 20px;
    height: 20px;
    top: 15px;
}

.select_opened .select_arrow {
    animation: rotate 0.2s ease-in-out forwards;
}

@keyframes rotate {
    0% { transform: rotateX(0deg); }
    100% { transform: rotateX(180deg); }
}

/* input */

.select input {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 18px 20px;
    padding-right: 40px;
    border-radius: 14px;
    border: 1px solid rgba(103, 103, 133, 0.5);
    color: var(--black);
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: border-color 0.2s ease-in, padding 0.15s ease-in;
}

.select input:hover + .select_arrow path {
    stroke: var(--orange);
}

.select_small input {
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
}

.select_error input {
    border-color: #F25858;
}

.select input:focus {
    outline: none;
    border-color: var(--grey-01);
}

/* select container */

.select_container {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    padding: 20px;
    padding-top: 76px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    align-items: flex-end;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    animation: slideDown 0.2s linear;
}

@keyframes slideDown {
    0% { transform: translateY(-50%) scaleY(0); opacity: 0; }
    100% { transform: translateY(0) scaleY(1); opacity: 1; }
}

.select_small .select_container {
    padding: 15px;
    padding-top: 64px;
}

/* select options */

.select .options {
    width: 100%;
    /* max-height: 74px; */
    /* max-height: 130px; */
    max-height: 190px;
    overflow-x: hidden;
    overflow-y: auto;
}

.select_small .options {
    /* max-height: 110px; */
    /* max-height: 136px; */
    max-height: 152px;
    padding: 0;
}

/* select option */

.select .option {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--grey-01);
    cursor: pointer;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    transition: color 0.3s ease-in-out;
}

.select .option:hover {
    color: var(--orange);
}

.select .option_disabled:hover {
    cursor: auto;
    color: var(--grey-01);
}

.select_small .option {
    line-height: 14px;
}

.select .option_active {
    color: var(--black);
}

.select .option_active:before {
    content: '-> '
}

.select .option:not(:last-child) {
    margin-bottom: 10px;
}

.select .option:last-child {
    margin-bottom: 2px;
}
