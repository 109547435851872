.title {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: var(--black);
}

.title_medium {
    font-size: 24px;
    line-height: 32px;
}

.title_small {
    font-size: 16px;
    line-height: 22px;
}