.table_container {
    max-width: calc(100vw - 30px - 40px - 334px);
    max-height: 71vh;

    overflow-x: auto;
    overflow-y: auto;
}

.table_container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.table_container::-webkit-scrollbar-track {
    background: #E6E6F8;
}

.table_container::-webkit-scrollbar-thumb {
    background-color: var(--grey-01);
    border-radius: 7.5px;
}

.table {
    width: 100%;
}

.table_row {
    height: 32px;

    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;

    background-color: #fff;

    &:hover {
        background-color: #eee;
    }
}

.table_row_h {
    height: 80px;
    color: var(--grey-01);
    background-color: #E7E7F9;

    &:hover {
        background-color: #E7E7F9;
    }
}

.table_item {
    overflow-x: auto;
    /* border-radius: 14px; */

    &:not(:first-child) {
        text-align: center;
    }

    &:first-child {
        padding-left: 20px;
        width: 310px;
        min-width: 310px;
        text-align: left;

        border-radius: 10px 0 0 10px;
    }

    &:last-child {
        border-radius: 0 10px 10px 0;
    }
}

.table_row_h .table_item {
    &:not(:first-child) {
        /* writing-mode: vertical-rl; */
        transform: rotate(-45deg);
    }
}

.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.menu_link {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-01);
    background-color: transparent;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.menu_link_active {
    color: var(--black);
    text-decoration: underline;
}

.menu_link:hover {
    color: var(--orange);
}