.alert {
    font-family: var(--font);
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    padding: 0 15px;
    height: 50px;

    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;

    color: var(--grey-01);

    background-color: #fff;
    border: 1px solid #E7E7F9;
    border-radius: 14px;
    animation: alert 0.7s;
}

.alert_message {
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.alert_message:hover {
    color: var(--orange);
}

@keyframes alert {
    0% {
        height: 0;
    }
    5% {
        height: 50px;
        border-color: var(--orange);
    }
    70% {
        border-color: var(--orange);
    }
}

.alert_close_btn {
    height: 20px;
    width: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border: none;
}

.alert_close_btn svg {
    cursor: pointer;
}

.alert_close_btn path {
    stroke: var(--grey-01);
    -webkit-transition: stroke 0.3s ease-in-out;
    -o-transition: stroke 0.3s ease-in-out;
    transition: stroke 0.3s ease-in-out;
}

.alert_close_btn svg:hover path {
    stroke: var(--orange);
}