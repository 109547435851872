.info_table {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.info_table_item {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* padding: 0 20px; */
    padding: 16px 20px;
    /* height: 50px; */
    min-height: 50px;

    display: flex;
    gap: 15px;
    align-items: center;

    background-color: #fff;
    border-radius: 14px;

    color: var(--black);
}

.info_table_item > * {
    /* white-space: nowrap; */
    /* overflow-x: auto; */
    overflow-y: hidden;
}

.info_table_label {
    font-weight: 800;
    width: 30%;
}

.info_table_text {
    width: 70%;
    display: flex;
    gap: 6px;
    align-items: center;
}

/* copy btn */

.copy_btn {
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    line-height: 0;
}

.copy_btn svg {
    width: 16px;
    height: 16px;
}

.copy_btn svg > * {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.copy_btn svg:hover > * {
    stroke: var(--orange);
}