.export {
    display: flex;
    gap: 14px;
    align-items: center;
    
}

.export p {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-01);
    order: 1;
    cursor: pointer;
}

.export p:hover + .export_button,
.export_button:hover {
    background-color: var(--orange);
}

.export p:hover + .export_button path:nth-child(1),
.export_button:hover path:nth-child(1) {
    fill: #fff;
}

.export p:hover + .export_button path:nth-child(2),
.export_button:hover path:nth-child(2) {
    stroke: #fff;
}

/* export button */

.export_button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

/* export icon */

.export_icon path:nth-child(1) {
    fill: var(--grey-01);
    transition: fill 0.3s ease-in-out;
}

.export_icon path:nth-child(2) {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}
