
.main {
    display: flex;
    gap: 40px;
    padding: 50px 0;
}

.main_left {
    width: 335px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main_right {
    max-width: min(calc(100vw - 335px - 40px - 30px), 1536px);

    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex: 1;
}