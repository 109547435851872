/* checkbox styles */

.checkbox {
    height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox svg {
    /* pointer-events: none; */
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
}

.checkbox input {
    opacity: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: none;
    position: absolute;
}

.checkbox label {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
}

.checkbox_disabled svg {
    cursor: not-allowed;
}

.checkbox_disabled svg,
.checkbox_disabled label {
    opacity: 0.5;
}

/* checkbox svg animation */

.animate_svg {
    transition: 0.2s ease-in;
}