/* pagination styles */

:root {
    --limits-width: 40px;
    --limits-size: 14px;
}

.limits {
    display: flex;
    gap: 4px;
    position: relative;

    width: fit-content;
}

.limits::after {
    position: absolute;
    content: '';
    width: var(--limits-width);
    height: var(--limits-width);
    border-radius: 50%;
    background-color: var(--grey-01);
    z-index: -1;
    transition: left 0.12s ease-in-out;
}

.limits_1::after {
    left: calc(0 * (var(--limits-width) + 4px));
}
.limits_2::after {
    left: calc(1 * (var(--limits-width) + 4px));
}
.limits_3::after {
    left: calc(2 * (var(--limits-width) + 4px));
}
.limits_4::after {
    left: calc(3 * (var(--limits-width) + 4px));
}
.limits_5::after {
    left: calc(4 * (var(--limits-width) + 4px));
}


/* page btn styles */

.limits_item {
    font-family: var(--grey-01);
    font-style: normal;
    font-weight: 700;
    font-size: var(--limits-size);
    line-height: 22px;
    border: 1px solid transparent;
    border-radius: 50%;
    height: var(--pagination-width);
    width: var(--pagination-width);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-01);
    transition: all 0.3s ease-in-out;
}

.limits_item:hover {
    color: var(--orange);
    cursor: pointer;
}

.limits_item_selected {
    animation: zoomIn 0.15s 0.13s ease-in-out forwards;
}

@keyframes zoomIn {
    20%, 80% {
        transform: scale(1.25);
    }
}

.limits_item_selected,
.limits_item_selected:hover {
    color: #fff;
    /* background-color: var(--grey-01); */
}

.limits_item_placeholder:hover {
    cursor: auto;
    color: var(--grey-01);
    border-color: transparent;
}

/* arrow btn styles */

.limits_btn {
    border: 1px solid var(--grey-01);
    border-radius: 50%;
    background-color: transparent;
    height: var(--limits-width);
    width: var(--limits-width);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.limits_btn path {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.limits_btn:hover path {
    stroke: var(--orange);
}

.limits_btn_left {
    transform: rotate(180deg);
}

.limits_btn svg {
    margin-left: 3px;
}

.limits_btn:hover {
    border-color: var(--orange);
    cursor: pointer;
}