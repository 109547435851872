@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-400.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-500.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Inter-700.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

:root {
    --orange: #ED692A;
    --black: #12123B;
    /* --black: #ededc4; */
    --grey-01: #676785;
    /* --grey-01: #98987a; */
    --grey-02: #F5F5FF;
    /* --grey-02: #0a0a00; */
    --grey-03: #F0F0FC;
    /* --grey-03: #0f0f03; */

    --font: 'Inter';
    /* #B2B2C1 */

    --min-width: 1400px;
    --max-width: 1920px;
    --container-side-padding: 15px;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
    font-synthesis: none;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

*::-webkit-scrollbar-track {
    background: #E6E6F8;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--grey-01);
    border-radius: 5px;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    background-color: var(--grey-02);
}

fieldset {
    border: none;
}

button {
    border: none;
}

.app {
    position: relative;
}

.flex-sb-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-column-10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}