/* pagination styles */

:root {
    --pagination-width: 40px;
    --pagination-size: 14px;
}

.pagination {
    display: flex;
    gap: 4px;
    position: relative;

    width: fit-content;
}

.pagination::after {
    position: absolute;
    content: '';
    width: var(--pagination-width);
    height: var(--pagination-width);
    border-radius: 25px;
    background-color: var(--grey-01);
    z-index: -1;
    transition: left 0.12s ease-in-out;
}

.pagination_1::after {
    left: calc(1 * (var(--pagination-width) + 4px));
}
.pagination_2::after {
    left: calc(2 * (var(--pagination-width) + 4px));
}
.pagination_3::after {
    left: calc(3 * (var(--pagination-width) + 4px));
}
.pagination_4::after {
    left: calc(4 * (var(--pagination-width) + 4px));
}
.pagination_5::after {
    left: calc(5 * (var(--pagination-width) + 4px));
}


/* page btn styles */

.pagination_item {
    font-family: var(--grey-01);
    font-style: normal;
    font-weight: 700;
    font-size: var(--pagination-size);
    line-height: 22px;
    border: 1px solid transparent;
    border-radius: 50%;
    height: var(--pagination-width);
    width: var(--pagination-width);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-01);
    transition: all 0.3s ease-in-out;
}

.pagination_item:hover {
    color: var(--orange);
    cursor: pointer;
}

.pagination_item_selected {
    animation: zoomIn 0.15s 0.13s ease-in-out forwards;
}

@keyframes zoomIn {
    20%, 80% {
        transform: scale(1.25);
    }
}

.pagination_item_selected,
.pagination_item_selected:hover {
    color: #fff;
    /* background-color: var(--grey-01); */
}

.pagination_item_placeholder:hover {
    cursor: auto;
    color: var(--grey-01);
    border-color: transparent;
}

/* arrow btn styles */

.pagination_btn {
    border: 1px solid var(--grey-01);
    border-radius: 50%;
    background-color: transparent;
    height: var(--pagination-width);
    width: var(--pagination-width);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.pagination_btn path {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.pagination_btn:hover path {
    stroke: var(--orange);
}

.pagination_btn_left {
    transform: rotate(180deg);
}

.pagination_btn svg {
    margin-left: 3px;
}

.pagination_btn:hover {
    border-color: var(--orange);
    cursor: pointer;
}