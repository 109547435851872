.text {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--black);
    transition: all 0.3s ease-in-out;
}

.text_login {
    font-family: monospace;
    color: #F25858;
}

.text_medium {
    font-size: 14px;
    line-height: 18px;
}

.text_small {
    font-size: 12px;
    line-height: 16px;
}