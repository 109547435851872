.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 50;
}

.overlay_scroll_y {
    width: 100%;
    padding: 35px 20px;
    overflow-y: scroll;
}

.overlay_scroll_y > * {
    margin: 0 auto;
}