/* close btn */

.close_btn {
    background-color: transparent;
    cursor: pointer;
    line-height: 0;
}

.close_btn svg {
    width: 18px;
    height: 18px;
}

.close_btn_sm svg {
    width: 16px;
    height: 16px;
}

.close_btn path {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.close_btn:hover path {
    stroke: var(--orange);
}