/* profile data */

.profile_data {
    position: relative;
    width: fit-content;
}

.profile_data > div:first-child {
    display: flex;
    gap: 14px;
    align-items: center;
    cursor: pointer;
}

.profile_data > div:hover .profile_icon rect {
    fill: var(--orange);
}

.profile_data > div:hover .profile_icon path,
.profile_data > div:hover .profile_icon circle {
    stroke: #fff;
}

.profile_data > div:hover .profile_name {
    color: var(--orange);
}

.profile_data > div:hover .profile_arrow path {
    stroke: var(--orange);
}

/* profile image */

.profile_icon_container {
    position: relative;
}

.profile_icon rect {
    fill: #F0F0FC;
    transition: 0.3s ease-in-out;
}

.profile_icon path,
.profile_icon circle {
    stroke: var(--grey-01);
    transition: 0.3s ease-in-out;
}

/* profile name */

.profile_name {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-01);
    transition: 0.3s ease-in-out;
}

/* profile arrow */

.profile_arrow path {
    stroke: var(--grey-01);
    transition: 0.3s ease-in-out;
}

.profile_arrow_opened {
    animation: rotate 0.2s ease-in-out forwards;
}

@keyframes rotate {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}

/* profile menu */

.profile_menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    z-index: 1;
    box-shadow: 0px 4px 80px rgba(32, 36, 57, 0.1);
    animation: fadeIn 0.2s ease-in-out forwards;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    gap: 10px;
}

.profile_menu button {
    font-weight: 700;
    padding: 5px 10px;
    color: var(--grey-01);
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.profile_menu button:hover {
    color: var(--orange);
}

/* @keyframes fadeIn {
    0% { opacity: 0; height: 0px; }
    100% { opacity: 1; height: fit-content; }
} */

.profile_status_dots {
    position: absolute;
    bottom: 0;
    display: flex;
    /* gap: 4px; */
    justify-content: center;
    width: 100%;
}

.profile_status_dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #E7E7F9;
    border: 2px solid #fff;
}

.profile_status_dot_green {
    background-color: var(--grey-01);
}