.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
}

.menu_link {
    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-01);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.menu_link_active {
    color: var(--black);
}

.menu_link:hover {
    color: var(--orange);
}
