.logo {
    width: 218px;
}

.logo_small {
    width: 189px;
}

.logo_login {
    width: 294px;
}