.input {
    position: relative;
    overflow: hidden;
}

/* search input search icon */

.search_icon {
    left: 20px;
    bottom: 20px;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    opacity: 1;
    animation: fromLeft 0.12s ease-in forwards;
}

@keyframes fromLeft {
    0% { left: -14px; opacity: 0; }
}

/* search input clear icon */

.close_icon {
    right: 20px;
    bottom: 20px;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    opacity: 1;
    cursor: pointer;
    animation: fromRight 0.12s ease-in forwards;
}

.close_icon path {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.close_icon:hover path {
    stroke: var(--orange);
}

@keyframes fromRight {
    0% { right: -14px; opacity: 0; }
}

.input_small .search_icon,
.input_small .close_icon {
    bottom: 15px;
}

.input_small .search_icon {
    left: 15px;
}

.input_small .close_icon {
    right: 15px;
}

/* input styles */

.input input {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 18px 20px;
    border-radius: 14px;
    border: 1px solid rgba(103, 103, 133, 0.5);
    color: var(--black);
    transition: border-color 0.2s ease-in, padding 0.12s ease-in;
}

.input input[type='date'] {
    height: 50px;
    width: 132px;
    font-size: 0.8em;
}

.input_login input {
    padding: 20px;
    font-family: monospace;

    background-color: transparent;
    border-radius: 40px;
    border: 1px solid rgba(241, 241, 241, 0.60);
}

.input_login input:not(:placeholder-shown) {
    color: #F1F1F1;
}

.input_small input {
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
}

.input_search input {
    padding-left: 54px;
}

.input_search.input_small input {
    padding-left: 49px;
}

.input_search input:not(:placeholder-shown) {
    padding-left: 20px;
    padding-right: 54px;
}

.input_search.input_small input:not(:placeholder-shown) {
    padding-left: 15px;
    padding-right: 49px;
}

.input input:focus {
    outline: none;
    border-color: var(--grey-01);
}

.input_error input {
    border-color: #F25858;
}

/* copy btn */

.copy_btn {
    right: 12px;
    top: 18px;
    position: absolute;
    z-index: 1000;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    line-height: 0;

    width: 24px;
    height: 24px;
}

.copy_btn > * {
    stroke: var(--grey-01);
    transition: stroke 0.3s ease-in-out;
}

.copy_btn:hover > * {
    stroke: var(--orange);
}
