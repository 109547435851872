/* label styles */

.label {
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    font-weight: 400;
    color: var(--grey-01);    
}

.label_login {
    font-family: monospace;
    color: #F1F1F1;
}

.label_small {
    font-size: 12px;
    line-height: 16px;
}

.label_required::after {
    content: ' *';
    color: #F25858;
}