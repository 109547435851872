.header {
    min-width: calc(var(--min-width) + var(--container-side-padding) * 2);
    background: #FFFFFF;
    box-shadow: 0px 4px 80px rgba(32, 36, 57, 0.1);
}

.header_container {
    height: 80px;
    display: flex;
    gap: 137px;
    align-items: center;
}

.header_container > *:last-child {
    margin-left: auto;
}