.tag {
    padding: 6px 10px;
    background-color: var(--grey-03);
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
}

.tag_opened::before,
.tag_duplicate::before,
.tag_closed::before {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 4px;
}

.tag_opened::before {
    background-color: #49DE60;
}

.tag_duplicate::before {
    background-color: var(--grey-01);
}

.tag_closed::before {
    background-color: #F25858;
}

.tag p {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--black);
}

.tag_button {
    stroke: var(--grey-01);
    cursor: pointer;
    transition: stroke 0.3s ease-in-out;
}

.tag_button:hover {
    stroke: var(--orange);
}